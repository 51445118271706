<template>
	<div class="event col">
		<h1 class="theme_text center">已走演{{_counter | numberFilter}}個鄉鎮</h1>
		<div class="filters row-grid" align-x="right" align-y="center">
			<el-button @click="handleClearFilter()" type="info" size="mini" round :disabled="!_.some(query, Boolean)">清除篩選</el-button>
			<el-select v-model="query.city" filterable size="small" class="round" @change="handleCityFilterChange()">
				<template v-for="(val, key, i) in _filterList">
					<el-option :value="key" :key="i" />
				</template>
			</el-select>
			<el-select v-model="query.district" filterable size="small" class="round" @change="handleDistrictFilterChange()">
				<template v-for="(option, i) in _filterList[query.city]">
					<el-option :value="option" :key="i" />
				</template>
			</el-select>
		</div>
		<br>
		<table v-if="$root.rwd < 2">
			<tr>
				<th class="theme_text">場次</th>
				<th class="theme_text">年份</th>
				<th class="theme_text">日期</th>
				<th class="theme_text">縣市</th>
				<th class="theme_text">鄉鎮</th>
				<th class="theme_text">地點</th>
			</tr>
			<tr v-for="(item, i) in _eventList" :key="i">
				<td>{{item.session}}</td>
				<td>{{item.date | year}}</td>
				<td>{{item.date | date}}</td>
				<td>{{item.city}}</td>
				<td>{{item.district}}</td>
				<td>{{item.spot}}</td>
			</tr>
		</table>
		<table v-else>
			<tr v-for="(item, i) in _eventList" :key="i">
				<td>
					<b class="col" gutter="5">
						<small class="theme_text">場次：{{item.session}}</small>
						<span style="font-size:1.3em;">{{item.city}}{{"\u3000"}}{{item.district}}</span>
						<small>{{item.spot}}</small>
						<small class="theme_text">{{item.date | dateFilter}}</small>
					</b>
				</td>
			</tr>
		</table>
		<br>
		<div class="center">
			<el-pagination :total="_total" :page-size="Number(query.size)" :current-page="Number(query.page)" @current-change="handleChangePage" layout="prev, pager, next" background />
		</div>
	</div>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions } from "vuex"
export default {
	data() {
		return {
			query: {
				city: "",
				district: ""
			}
		}
	},
	computed: {
		...mapState("event", [
			"_total",
			"_counter",
			"_eventList",
			"_filterList"
		])
	},
	filters: {
		year(val) {
			return val && moment(val).tz("Asia/Taipei").format("YYYY");
		},
		date(val) {
			return val && moment(val).tz("Asia/Taipei").format("M 月 D 日");
		}
	},
	methods: {
		...mapActions("event", ["_getList"]),
		async handleSearch() {
			const query = {
				...this.$route.query,
				...this.query
			};
			this.$router.push({
				path: this.$route.path,
				query
			});
			await this._getList(query);
		},
		handleCityFilterChange() {
			this.query.district = "";
			this.query.page = 1;
			this.handleSearch();
		},
		handleDistrictFilterChange() {
			this.query.page = 1;
			this.handleSearch();
		},
		handleChangePage(page) {
			this.$set(this.query, "page", page);
			this.handleSearch();
		},
		handleClearFilter() {
			this.$router.push(this.$route.path);
			this.$set(this.$data, "query", new Object);
			this._getList(this.query);
		},
	},
	async created() {
		const {
			page = 1,
			size = 10,
			city = "",
			district = ""
		} = this.$route.query;
		this.$set(this.query, "page", Number(page));
		this.$set(this.query, "size", Number(size));
		this.$set(this.query, "city", city);
		this.$set(this.query, "district", district);
		await this._getList(this.query);
	}
}
</script>

<style lang="scss" scoped>
.filters {
	> * {
		margin-top: 10px;
		margin-left: 10px;
		max-width: 150px;
	}
}
table {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	@media screen and (min-width: 769px) {
		text-align: center;

		tr:nth-child(even) {
			background: $theme-background;
		}
	}
	th,
	td {
		padding: 1em 2em;
	}
	@media screen and (max-width: 768px) {
		border-collapse: separate;
		border-spacing: 20px;
		td {
			padding: 0;
		}
		tr:not(:last-child) {
			td {
				padding-bottom: 20px;
				border-bottom: 1px solid $theme-light;
			}
		}
	}
}
</style>